import {
  customerBetTypeSport,
  lobbyTypeHome,
} from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult } from '@core/router';
import { useCustomerBetTypeStore } from '@core/customer';
import { AbstractPrefetch } from '@core/router';

import { useLobbyStore } from 'web/src/modules/lobby/store';

export default class CustomHomePagePrefetch extends AbstractPrefetch {
  prefetch(): AbstractPrefetchResult {
    const betTypeStore = useCustomerBetTypeStore();

    if (betTypeStore.betType) {
      return ({
        name: betTypeStore.betType === customerBetTypeSport ? RouteName.SPORTLINE_TOP_EVENTS : CasinoRouteName.CASINO_LOBBY,
      });
    }

    const { getLobbyItems, loadLobbyItems } = useLobbyStore();
    const items = getLobbyItems(lobbyTypeHome);
    if (!items.value.length) {
      void loadLobbyItems({
        type: lobbyTypeHome,
      });
    }
  }
}
