<script setup lang="ts">
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';
import VFadeCarousel from 'web/src/components/FadeCarousel/VFadeCarousel/VFadeCarousel.vue';
import VFadeCarouselNavigationButtons from 'web/src/components/FadeCarousel/VFadeCarouselNavigationButtons/VFadeCarouselNavigationButtons.vue';
import VFadeCarouselPointsPagination from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import VFadeCarouselSlide from 'web/src/components/FadeCarousel/VFadeCarouselSlide/VFadeCarouselSlide.vue';
import CustomHomepageBanner from 'web/src/modules/banners/components/CustomHomepageBanner/CustomHomepageBanner.vue';
import useMainBannerSectionRouteComponent
  from 'web/src/modules/banners/components/MainBannerSectionRouteComponent/composables/useMainBannerSectionRouteComponent';
import { BannerLocation } from 'web/src/modules/banners/enums';

const {
  currentGroup,
} = useMainBannerSectionRouteComponent({
  location: BannerLocation.TOP_CUSTOM_HOME_PAGE,
});
</script>

<template>
  <div v-auto-id="'CustomHomepageBanners'"
    v-if="currentGroup?.banners.length"
    :class="$style['custom-homepage-banners']"
  >
    <VFadeCarousel
      :autoplay-timeout="currentGroup.autoplayDelay"
    >
      <VFadeCarouselSlide
        v-for="(banner, index) in currentGroup.banners"
        :key="index"
        :current-slide-index="index"
      >
        <CustomHomepageBanner
          :banner="banner.content"
          :is-nt="banner.isNT"
        />
      </VFadeCarouselSlide>
      <template #pagination>
        <VFadeCarouselNavigationButtons v-if="'1'" />
        <VFadeCarouselPointsPagination
          :pagination-location="PaginationLocation.RIGHT"
        />
      </template>
    </VFadeCarousel>
  </div>
</template>

<style lang="scss" module>
.custom-homepage-banners {
  position: relative;
  overflow: hidden;

  :global(img) {
    user-select: none;
    -webkit-user-drag: none;
  }
}
</style>
