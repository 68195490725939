<script setup lang="ts">
import {
  computed,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import {
  CustomerBetType,
  lobbyTypeHome,
} from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { EventActionGoogleAnalytics, useGoogleAnalytics } from '@core/analytics';
import { useIsLoggedIn } from '@core/auth';
import { useCustomerBetTypeStore, useCustomerDataStore } from '@core/customer';
import { useSiteConfigStore } from '@core/site-config';

import type { LobbySkeletonItem } from 'web/src/modules/lobby/types';
import CustomHomepageBanners from 'web/src/modules/banners/components/CustomHomepageBanners/CustomHomepageBanners.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import ShowcaseNavigationRouteComponent from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';

const router = useRouter();
const customerDataStore = useCustomerDataStore();
const { pushGTM } = useGoogleAnalytics();
const { isLoggedIn } = useIsLoggedIn();

const betType = toRef(useCustomerBetTypeStore(), 'betType');
const egsSkeletons = toRef(useSiteConfigStore(), 'egsSkeletons');
const customerId = toRef(() => customerDataStore.customerData?.customerLogin);
const skeletons = computed<LobbySkeletonItem[]>(() => (
  egsSkeletons.value?.home ?? []
));

if (null) {
  watch(betType, (newValue) => {
    if (newValue) {
      void router.replace({
        name: newValue === CustomerBetType.SPORT ? RouteName.SPORTLINE_TOP_EVENTS : CasinoRouteName.CASINO_LOBBY,
      });
    }
  }, { immediate: true });
}

useKeepAlive();

function sendAnalyticsNewHomePageView(): void {
  pushGTM(AnalyticsEvent.NEW_HOMEPAGE_VIEW, {
    event: AnalyticsEvent.NEW_HOMEPAGE_VIEW,
    event_category: 'New homepage',
    event_action: EventActionGoogleAnalytics.PAGE_VIEW,
  });
}

watch(customerId, (newValue) => {
  if (isLoggedIn.value && !newValue) {
    return;
  }

  sendAnalyticsNewHomePageView();
}, {
  immediate: true,
});
</script>

<template>
  <div v-auto-id="'CustomHomeRoutePage'" :class="$style['custom-home']">
    <CustomHomepageBanners
      :class="$style['custom-home__banners']"
    />
    <ShowcaseNavigationRouteComponent
      is-custom-homepage
      :class="$style['custom-home__showcase']"
    />
    <LobbyBlock
      :lobby-type="lobbyTypeHome"
      :skeletons="skeletons"
      prevent-items-fetch
      is-custom-homepage
      is-full-height
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .custom-home {
    @include limit-content-width($isDesktop);

    padding: if($isDesktop, 0 16px, 0);

    @if $isDesktop {
      overflow: hidden;
    }

    &__banners {
      margin-bottom: 16px;

      @if not $isDesktop {
        padding: 0 8px;
        margin-top: 8px;
      }
    }
  }
}
</style>
